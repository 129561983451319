import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { ThreeDots } from "react-loader-spinner";
import { useTable } from "react-table";
import { Link } from "react-router-dom";

const base64 = require("base-64");

const PaperWiseTable = ({ baseUrl, lastUrl, columns2 }) => {
  const [url2Data, setUrl2Data] = useState([]);
  const [passedUsers, setPassedUsers] = useState([]);
  const [failedUsers, setFailedUsers] = useState([]);
  const [correct, setCorrect] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const pairs = document.cookie.split(";");
  let cookies = {};
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
  }
  const authToken = base64.encode(cookies.userid + ":" + cookies.ver);

  useEffect(() => {
    const fetchUrl2Data = async () => {
      if (!lastUrl) {
        return;
      }
      setIsLoading(true);
      setError(null);

      let myHeaders = new Headers();
      myHeaders.append("Authorization", "Basic " + authToken);
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      try {
        const url2 = `${baseUrl}total_unique_user_paper_wise/all/${lastUrl}/all`;
        const response = await fetch(url2, requestOptions);
        const data = await response.json();

        setUrl2Data(data.data);
      } catch (error) {
        console.error("Error fetching data from url2:", error);
        setError(error.message || "Failed to fetch data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchUrl2Data();
  }, [baseUrl, lastUrl]);

  useEffect(() => {
    if (url2Data.length > 0) {
      const passedUsersList = url2Data.map((item) => item.totalPassedUser);
      const failedUsersList = url2Data.map((item) => item.totalFailedUser);

      const correct = url2Data.map((item) => item.Correct);

      setPassedUsers(passedUsersList);
      setFailedUsers(failedUsersList);

      setCorrect(correct);
    }
  }, [url2Data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: columns2,
      data: url2Data,
    });

  if (isLoading) {
    return (
      <div className="admin-table text-center">
        <ThreeDots color="darkblue" size={50} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin-table text-center">
        <p className="text-danger">Error: {error}</p>
      </div>
    );
  }

  if (!url2Data.length) {
    return (
      <div className="admin-table">
        <h4
          style={{
            fontSize: "1em",
            margin: "15px",
            fontWeight: "bold",
            color: "rgba(18,21,78,.7)",
          }}
        >
          TOTAL USERS PAPER WISE
        </h4>
        <hr className="underline" />
        <p className="text-muted text-center">No data available</p>
      </div>
    );
  }

  return (
    <div className="admin-table">
      <h4
        style={{
          fontSize: "1em",
          margin: "15px",
          fontWeight: "bold",
          color: "rgba(18,21,78,.7)",
        }}
      >
        TOTAL USERS PAPER WISE
      </h4>

      <hr className="underline" />
      {url2Data.length === 0 ? (
        <span
          style={{
            display: "block",
            width: "50px",
            margin: "auto",
            position: "inline",
            textAlign: "center",
          }}
        >
          <ThreeDots color="darkblue" size={50} />
        </span>
      ) : (
        <table
          style={{ width: "100%", borderCollapse: "collapse" }}
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={`header-${index}`}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    key={column.id}
                    style={{
                      color: "black",
                      backgroundColor: "white",
                      textAlign: "left",
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row);
              // Calculate the correct score percentage
              const totalUsers =
                parseInt(row.original.totalPassedUser) +
                parseInt(row.original.totalFailedUser);
              const scorePercentage =
                totalUsers > 0
                  ? Math.round(
                      (parseInt(row.original.total100PercScoreCount) /
                        totalUsers) *
                        100
                    )
                  : 0;

              return (
                <tr {...row.getRowProps()} key={`row-${rowIndex}`}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      key={cell.column.id}
                      style={{
                        padding: "0px",
                        border: "none",
                      }}
                    >
                      {cell.column.id === "nameQuizzNameId" ? (
                        <>
                          <span style={{ fontWeight: "bold" }}>
                            {row.original.Name}
                          </span>
                          <br />
                          <span
                            style={{ fontWeight: "lighter", fontSize: "16px" }}
                          >
                            {row.original.group_name}
                          </span>
                        </>
                      ) : cell.column.id === "nameTotalUsersId" ? (
                        <>
                          <div
                            className="chart2"
                            style={{
                              height: "93px",
                              textAlign: "left",
                            }}
                          >
                            <p style={{ marginLeft: "10px" }}>
                              {cell.render("Cell")}
                            </p>

                            {row.original.totalPassedUser &&
                              row.original.totalFailedUser && (
                                <PieChart
                                  series={[
                                    {
                                      data: [
                                        {
                                          id: "totalPassUser",
                                          value: parseInt(
                                            row.original.totalPassedUser
                                          ),
                                          color: "#71DEB9",
                                        },
                                        {
                                          id: "totalFailUser",
                                          value: parseInt(
                                            row.original.totalFailedUser
                                          ),
                                          color: "red",
                                        },
                                      ],
                                    },
                                  ]}
                                  width={150}
                                />
                              )}
                          </div>
                        </>
                      ) : cell.column.id === "nameScoreId" ? (
                        <>
                          <span className="chart2">
                            {cell.render("Cell")}
                            <Box
                              sx={{
                                position: "relative",
                                display: "inline-flex",
                              }}
                            >
                              <CircularProgress
                                variant="determinate"
                                value={scorePercentage}
                                color="secondary"
                                thickness={2}
                              />
                              <Box
                                sx={{
                                  inset: "0",
                                  position: "absolute",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  component="div"
                                  color="text.secondary"
                                  sx={{ fontSize: "0.7rem" }}
                                >{`${scorePercentage}%`}</Typography>
                              </Box>
                            </Box>
                          </span>
                        </>
                      ) : cell.column.id === "nameActionsId" ? (
                        <>
                          <Link
                            to={`/admin/paperwise-details/${row.original.QuizId}`}
                          >
                            <button
                              style={{
                                border: "none",
                                padding: "10px",
                                backgroundColor: "#3ac47d",
                                color: "white",
                                borderRadius: "4px",
                              }}
                            >
                              Details
                            </button>
                          </Link>
                        </>
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default PaperWiseTable;
